/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as goal_transaction_enums002 from './goal-transaction-enums.pb';
import * as goal_transaction_type_enums003 from './goal-transaction-type-enums.pb';
import * as goal_transaction_payment_enum004 from './goal-transaction-payment-enum.pb';
import * as goal_transaction_origin_type_enum005 from './goal-transaction-origin-type-enum.pb';
import * as common_message006 from './common-message.pb';
/**
 * Message implementation for configparam.AddConfigParamRequest
 */
export class AddConfigParamRequest implements GrpcMessage {
  static id = 'configparam.AddConfigParamRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddConfigParamRequest();
    AddConfigParamRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddConfigParamRequest) {
    _instance.code = _instance.code || '';
    _instance.description = _instance.description || '';
    _instance.value = _instance.value || '';
    _instance.valueType = _instance.valueType || '';
    _instance.status = _instance.status || '';
    _instance.userId = _instance.userId || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddConfigParamRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 2:
          _instance.code = _reader.readString();
          break;
        case 3:
          _instance.description = _reader.readString();
          break;
        case 4:
          _instance.value = _reader.readString();
          break;
        case 5:
          _instance.valueType = _reader.readString();
          break;
        case 6:
          _instance.status = _reader.readString();
          break;
        case 7:
          _instance.userId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.userId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    AddConfigParamRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddConfigParamRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.code) {
      _writer.writeString(2, _instance.code);
    }
    if (_instance.description) {
      _writer.writeString(3, _instance.description);
    }
    if (_instance.value) {
      _writer.writeString(4, _instance.value);
    }
    if (_instance.valueType) {
      _writer.writeString(5, _instance.valueType);
    }
    if (_instance.status) {
      _writer.writeString(6, _instance.status);
    }
    if (_instance.userId) {
      _writer.writeMessage(
        7,
        _instance.userId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
  }

  private _code: string;
  private _description: string;
  private _value: string;
  private _valueType: string;
  private _status: string;
  private _userId?: googleProtobuf000.Int32Value;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddConfigParamRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<AddConfigParamRequest.AsObject>) {
    _value = _value || {};
    this.code = _value.code;
    this.description = _value.description;
    this.value = _value.value;
    this.valueType = _value.valueType;
    this.status = _value.status;
    this.userId = _value.userId
      ? new googleProtobuf000.Int32Value(_value.userId)
      : undefined;
    AddConfigParamRequest.refineValues(this);
  }
  get code(): string {
    return this._code;
  }
  set code(value: string) {
    this._code = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get value(): string {
    return this._value;
  }
  set value(value: string) {
    this._value = value;
  }
  get valueType(): string {
    return this._valueType;
  }
  set valueType(value: string) {
    this._valueType = value;
  }
  get status(): string {
    return this._status;
  }
  set status(value: string) {
    this._status = value;
  }
  get userId(): googleProtobuf000.Int32Value | undefined {
    return this._userId;
  }
  set userId(value: googleProtobuf000.Int32Value | undefined) {
    this._userId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddConfigParamRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddConfigParamRequest.AsObject {
    return {
      code: this.code,
      description: this.description,
      value: this.value,
      valueType: this.valueType,
      status: this.status,
      userId: this.userId ? this.userId.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddConfigParamRequest.AsProtobufJSON {
    return {
      code: this.code,
      description: this.description,
      value: this.value,
      valueType: this.valueType,
      status: this.status,
      userId: this.userId ? this.userId.toProtobufJSON(options) : null
    };
  }
}
export module AddConfigParamRequest {
  /**
   * Standard JavaScript object representation for AddConfigParamRequest
   */
  export interface AsObject {
    code: string;
    description: string;
    value: string;
    valueType: string;
    status: string;
    userId?: googleProtobuf000.Int32Value.AsObject;
  }

  /**
   * Protobuf JSON representation for AddConfigParamRequest
   */
  export interface AsProtobufJSON {
    code: string;
    description: string;
    value: string;
    valueType: string;
    status: string;
    userId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for configparam.GetConfigParam
 */
export class GetConfigParam implements GrpcMessage {
  static id = 'configparam.GetConfigParam';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetConfigParam();
    GetConfigParam.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetConfigParam) {
    _instance.code = _instance.code || '';
    _instance.userId = _instance.userId || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetConfigParam,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.code = _reader.readString();
          break;
        case 2:
          _instance.userId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.userId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetConfigParam.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetConfigParam,
    _writer: BinaryWriter
  ) {
    if (_instance.code) {
      _writer.writeString(1, _instance.code);
    }
    if (_instance.userId) {
      _writer.writeMessage(
        2,
        _instance.userId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
  }

  private _code: string;
  private _userId?: googleProtobuf000.Int32Value;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetConfigParam to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetConfigParam.AsObject>) {
    _value = _value || {};
    this.code = _value.code;
    this.userId = _value.userId
      ? new googleProtobuf000.Int32Value(_value.userId)
      : undefined;
    GetConfigParam.refineValues(this);
  }
  get code(): string {
    return this._code;
  }
  set code(value: string) {
    this._code = value;
  }
  get userId(): googleProtobuf000.Int32Value | undefined {
    return this._userId;
  }
  set userId(value: googleProtobuf000.Int32Value | undefined) {
    this._userId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetConfigParam.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetConfigParam.AsObject {
    return {
      code: this.code,
      userId: this.userId ? this.userId.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetConfigParam.AsProtobufJSON {
    return {
      code: this.code,
      userId: this.userId ? this.userId.toProtobufJSON(options) : null
    };
  }
}
export module GetConfigParam {
  /**
   * Standard JavaScript object representation for GetConfigParam
   */
  export interface AsObject {
    code: string;
    userId?: googleProtobuf000.Int32Value.AsObject;
  }

  /**
   * Protobuf JSON representation for GetConfigParam
   */
  export interface AsProtobufJSON {
    code: string;
    userId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for configparam.ListConfigParamResponse
 */
export class ListConfigParamResponse implements GrpcMessage {
  static id = 'configparam.ListConfigParamResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListConfigParamResponse();
    ListConfigParamResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListConfigParamResponse) {
    _instance.values = _instance.values || [];
    _instance.currentPage = _instance.currentPage || 0;
    _instance.pageCount = _instance.pageCount || 0;
    _instance.pageSize = _instance.pageSize || 0;
    _instance.rowCount = _instance.rowCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListConfigParamResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message006.ConfigParamModel();
          _reader.readMessage(
            messageInitializer1,
            common_message006.ConfigParamModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.currentPage = _reader.readInt32();
          break;
        case 3:
          _instance.pageCount = _reader.readInt32();
          break;
        case 4:
          _instance.pageSize = _reader.readInt32();
          break;
        case 5:
          _instance.rowCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ListConfigParamResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListConfigParamResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        common_message006.ConfigParamModel.serializeBinaryToWriter
      );
    }
    if (_instance.currentPage) {
      _writer.writeInt32(2, _instance.currentPage);
    }
    if (_instance.pageCount) {
      _writer.writeInt32(3, _instance.pageCount);
    }
    if (_instance.pageSize) {
      _writer.writeInt32(4, _instance.pageSize);
    }
    if (_instance.rowCount) {
      _writer.writeInt32(5, _instance.rowCount);
    }
  }

  private _values?: common_message006.ConfigParamModel[];
  private _currentPage: number;
  private _pageCount: number;
  private _pageSize: number;
  private _rowCount: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListConfigParamResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListConfigParamResponse.AsObject>) {
    _value = _value || {};
    this.values = (_value.values || []).map(
      m => new common_message006.ConfigParamModel(m)
    );
    this.currentPage = _value.currentPage;
    this.pageCount = _value.pageCount;
    this.pageSize = _value.pageSize;
    this.rowCount = _value.rowCount;
    ListConfigParamResponse.refineValues(this);
  }
  get values(): common_message006.ConfigParamModel[] | undefined {
    return this._values;
  }
  set values(value: common_message006.ConfigParamModel[] | undefined) {
    this._values = value;
  }
  get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(value: number) {
    this._currentPage = value;
  }
  get pageCount(): number {
    return this._pageCount;
  }
  set pageCount(value: number) {
    this._pageCount = value;
  }
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }
  get rowCount(): number {
    return this._rowCount;
  }
  set rowCount(value: number) {
    this._rowCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListConfigParamResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListConfigParamResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListConfigParamResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }
}
export module ListConfigParamResponse {
  /**
   * Standard JavaScript object representation for ListConfigParamResponse
   */
  export interface AsObject {
    values?: common_message006.ConfigParamModel.AsObject[];
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }

  /**
   * Protobuf JSON representation for ListConfigParamResponse
   */
  export interface AsProtobufJSON {
    values: common_message006.ConfigParamModel.AsProtobufJSON[] | null;
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }
}
