/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './chat.pb';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as goal_transaction_enums002 from './goal-transaction-enums.pb';
import * as goal_transaction_type_enums003 from './goal-transaction-type-enums.pb';
import * as goal_transaction_payment_enum004 from './goal-transaction-payment-enum.pb';
import * as goal_transaction_origin_type_enum005 from './goal-transaction-origin-type-enum.pb';
import * as common_message006 from './common-message.pb';
import * as googleProtobuf007 from '@ngx-grpc/well-known-types';
import { GRPC_CHAT_GRPC_SERVICE_CLIENT_SETTINGS } from './chat.pbconf';
/**
 * Service client implementation for chat.ChatGrpcService
 */
@Injectable({ providedIn: 'any' })
export class ChatGrpcServiceClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /chat.ChatGrpcService/Init
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.InitResponse>>
     */
    init: (
      requestData: thisProto.InitRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.InitResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/chat.ChatGrpcService/Init',
        requestData,
        requestMetadata,
        requestClass: thisProto.InitRequest,
        responseClass: thisProto.InitResponse
      });
    },
    /**
     * Unary call: /chat.ChatGrpcService/Chat
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ChatMessageModel>>
     */
    chat: (
      requestData: thisProto.ChatRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ChatMessageModel>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/chat.ChatGrpcService/Chat',
        requestData,
        requestMetadata,
        requestClass: thisProto.ChatRequest,
        responseClass: thisProto.ChatMessageModel
      });
    },
    /**
     * Unary call: /chat.ChatGrpcService/GetChat
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ChatModel>>
     */
    getChat: (
      requestData: thisProto.GetChatRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ChatModel>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/chat.ChatGrpcService/GetChat',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetChatRequest,
        responseClass: thisProto.ChatModel
      });
    },
    /**
     * Unary call: /chat.ChatGrpcService/UpdateChat
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ChatModel>>
     */
    updateChat: (
      requestData: thisProto.UpdateChatRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ChatModel>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/chat.ChatGrpcService/UpdateChat',
        requestData,
        requestMetadata,
        requestClass: thisProto.UpdateChatRequest,
        responseClass: thisProto.ChatModel
      });
    },
    /**
     * Unary call: /chat.ChatGrpcService/RemoveChat
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<googleProtobuf007.Empty>>
     */
    removeChat: (
      requestData: thisProto.RemoveChatRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<googleProtobuf007.Empty>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/chat.ChatGrpcService/RemoveChat',
        requestData,
        requestMetadata,
        requestClass: thisProto.RemoveChatRequest,
        responseClass: googleProtobuf007.Empty
      });
    },
    /**
     * Unary call: /chat.ChatGrpcService/ListChat
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ListChatResponse>>
     */
    listChat: (
      requestData: googleProtobuf007.Empty,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ListChatResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/chat.ChatGrpcService/ListChat',
        requestData,
        requestMetadata,
        requestClass: googleProtobuf007.Empty,
        responseClass: thisProto.ListChatResponse
      });
    },
    /**
     * Unary call: /chat.ChatGrpcService/ListChatMessage
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ListChatMessageResponse>>
     */
    listChatMessage: (
      requestData: thisProto.ListChatMessageRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ListChatMessageResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/chat.ChatGrpcService/ListChatMessage',
        requestData,
        requestMetadata,
        requestClass: thisProto.ListChatMessageRequest,
        responseClass: thisProto.ListChatMessageResponse
      });
    },
    /**
     * Unary call: /chat.ChatGrpcService/SetUserFeedbackRating
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ChatMessageModel>>
     */
    setUserFeedbackRating: (
      requestData: thisProto.SetUserFeedbackRatingRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ChatMessageModel>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/chat.ChatGrpcService/SetUserFeedbackRating',
        requestData,
        requestMetadata,
        requestClass: thisProto.SetUserFeedbackRatingRequest,
        responseClass: thisProto.ChatMessageModel
      });
    },
    /**
     * Unary call: /chat.ChatGrpcService/AddFeedbackToChatMessage
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ChatMessageFeedbackModel>>
     */
    addFeedbackToChatMessage: (
      requestData: thisProto.AddFeedbackToChatMessageRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ChatMessageFeedbackModel>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/chat.ChatGrpcService/AddFeedbackToChatMessage',
        requestData,
        requestMetadata,
        requestClass: thisProto.AddFeedbackToChatMessageRequest,
        responseClass: thisProto.ChatMessageFeedbackModel
      });
    },
    /**
     * Unary call: /chat.ChatGrpcService/GetChatOpenAiModelSummaryUsage
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetChatOpenAiModelSummaryUsageResponse>>
     */
    getChatOpenAiModelSummaryUsage: (
      requestData: thisProto.GetChatOpenAiModelSummaryUsageRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<
      GrpcEvent<thisProto.GetChatOpenAiModelSummaryUsageResponse>
    > => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/chat.ChatGrpcService/GetChatOpenAiModelSummaryUsage',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetChatOpenAiModelSummaryUsageRequest,
        responseClass: thisProto.GetChatOpenAiModelSummaryUsageResponse
      });
    },
    /**
     * Unary call: /chat.ChatGrpcService/GetAzureOpenAiModel
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.AzureOpenAiModel>>
     */
    getAzureOpenAiModel: (
      requestData: common_message006.BaseGetRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.AzureOpenAiModel>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/chat.ChatGrpcService/GetAzureOpenAiModel',
        requestData,
        requestMetadata,
        requestClass: common_message006.BaseGetRequest,
        responseClass: thisProto.AzureOpenAiModel
      });
    }
  };

  constructor(
    @Optional() @Inject(GRPC_CHAT_GRPC_SERVICE_CLIENT_SETTINGS) settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient('chat.ChatGrpcService', settings);
  }

  /**
   * Unary call @/chat.ChatGrpcService/Init
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.InitResponse>
   */
  init(
    requestData: thisProto.InitRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.InitResponse> {
    return this.$raw
      .init(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/chat.ChatGrpcService/Chat
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ChatMessageModel>
   */
  chat(
    requestData: thisProto.ChatRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ChatMessageModel> {
    return this.$raw
      .chat(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/chat.ChatGrpcService/GetChat
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ChatModel>
   */
  getChat(
    requestData: thisProto.GetChatRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ChatModel> {
    return this.$raw
      .getChat(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/chat.ChatGrpcService/UpdateChat
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ChatModel>
   */
  updateChat(
    requestData: thisProto.UpdateChatRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ChatModel> {
    return this.$raw
      .updateChat(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/chat.ChatGrpcService/RemoveChat
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<googleProtobuf007.Empty>
   */
  removeChat(
    requestData: thisProto.RemoveChatRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<googleProtobuf007.Empty> {
    return this.$raw
      .removeChat(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/chat.ChatGrpcService/ListChat
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ListChatResponse>
   */
  listChat(
    requestData: googleProtobuf007.Empty,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ListChatResponse> {
    return this.$raw
      .listChat(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/chat.ChatGrpcService/ListChatMessage
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ListChatMessageResponse>
   */
  listChatMessage(
    requestData: thisProto.ListChatMessageRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ListChatMessageResponse> {
    return this.$raw
      .listChatMessage(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/chat.ChatGrpcService/SetUserFeedbackRating
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ChatMessageModel>
   */
  setUserFeedbackRating(
    requestData: thisProto.SetUserFeedbackRatingRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ChatMessageModel> {
    return this.$raw
      .setUserFeedbackRating(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/chat.ChatGrpcService/AddFeedbackToChatMessage
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ChatMessageFeedbackModel>
   */
  addFeedbackToChatMessage(
    requestData: thisProto.AddFeedbackToChatMessageRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ChatMessageFeedbackModel> {
    return this.$raw
      .addFeedbackToChatMessage(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/chat.ChatGrpcService/GetChatOpenAiModelSummaryUsage
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetChatOpenAiModelSummaryUsageResponse>
   */
  getChatOpenAiModelSummaryUsage(
    requestData: thisProto.GetChatOpenAiModelSummaryUsageRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetChatOpenAiModelSummaryUsageResponse> {
    return this.$raw
      .getChatOpenAiModelSummaryUsage(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/chat.ChatGrpcService/GetAzureOpenAiModel
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.AzureOpenAiModel>
   */
  getAzureOpenAiModel(
    requestData: common_message006.BaseGetRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.AzureOpenAiModel> {
    return this.$raw
      .getAzureOpenAiModel(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
