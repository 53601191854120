import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BaseGetRequest,
  CurrencyModel,
  UserModel,
  WhereGetRequest,
} from '@bp-core/src/lib/proto/common-message.pb';
import { GetConfigParam } from '@bp-core/src/lib/proto/config-param.pb';
import { ConfigParamGrpcServiceClient } from '@bp-core/src/lib/proto/config-param.pbsc';
import { CurrencyGrpcServiceClient } from '@bp-core/src/lib/proto/currency.pbsc';
import { AuthService } from '@bp-core/src/lib/services/portal/auth.service';
import { Empty, Int32Value, StringValue } from '@ngx-grpc/well-known-types';
import {
  AzureOpenAiModel,
  ChatModel,
  GetChatOpenAiModelSummaryUsageRequest,
  GetChatRequest,
  InitRequest,
  OpenAiModelSummaryUsageModel,
  RemoveChatRequest,
} from 'projects/bp-core/src/lib/proto/chat.pb';
import { ChatGrpcServiceClient } from 'projects/bp-core/src/lib/proto/chat.pbsc';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  catchError,
  combineLatest,
  filter,
  map,
  of,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';
import { IChat } from './chat.types';

@Injectable({ providedIn: 'root' })
export class ChatService {
  private _chat: BehaviorSubject<IChat> = new BehaviorSubject(null);
  private _chats: BehaviorSubject<IChat[]> = new BehaviorSubject(null);
  private _showChat: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private currentUser: UserModel | undefined;
  private defaultModel: AzureOpenAiModel | undefined;
  private defaultModelSubject: ReplaySubject<AzureOpenAiModel> =
    new ReplaySubject<AzureOpenAiModel>(0);
  public readonly defaultModel$: Observable<AzureOpenAiModel> =
    this.defaultModelSubject.asObservable();

  private openAiModelsSummaryUsage: ReplaySubject<OpenAiModelSummaryUsageModel[] | undefined> =
    new ReplaySubject<OpenAiModelSummaryUsageModel[] | undefined>(0);
  public readonly openAiModelsSummaryUsage$: Observable<
    OpenAiModelSummaryUsageModel[] | undefined
  > = this.openAiModelsSummaryUsage.asObservable();
  private usdCurrencySubject: ReplaySubject<CurrencyModel> = new ReplaySubject<CurrencyModel>(0);
  public readonly usdCurrency$: Observable<CurrencyModel> = this.usdCurrencySubject.asObservable();
  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private chatGrpcServiceClient: ChatGrpcServiceClient,
    private configParamGrpcServiceClient: ConfigParamGrpcServiceClient,
    private authService: AuthService,
    private currencyGrpcServiceClient: CurrencyGrpcServiceClient,
  ) {
    this.currencyGrpcServiceClient
      .get(
        new BaseGetRequest({
          where: new WhereGetRequest({
            predicate: new StringValue({ value: 'Uuid = "dolar"' }),
          }),
        }),
      )
      .pipe(take(1))
      .subscribe({
        next: response => {
          this.usdCurrencySubject.next(response);
        },
      });

    this.authService.getCurrentUser().subscribe(user => {
      this.currentUser = user;
    });
  }

  get chat$(): Observable<IChat> {
    return this._chat.asObservable();
  }

  get showChat$(): Observable<boolean> {
    if (this._showChat.getValue() === null) {
      return this.getShowChat();
    }
    return this._showChat.asObservable();
  }
  get showChat(): boolean {
    return this._showChat.getValue();
  }
  getShowChat() {
    const userEnableChatConfigParam = new GetConfigParam();
    userEnableChatConfigParam.code = 'ENABLE-CHAT-USER';
    userEnableChatConfigParam.userId = new Int32Value({ value: this.currentUser?.id });

    const UserEnableChatObs = this.configParamGrpcServiceClient.get(userEnableChatConfigParam).pipe(
      catchError(error => {
        return of({ value: 'true' });
      }),
    );

    const chatEnableParamRequest = new GetConfigParam();
    chatEnableParamRequest.code = 'ENABLE-CHAT';

    const chatEnableParamObs = this.configParamGrpcServiceClient.get(chatEnableParamRequest).pipe(
      catchError(error => {
        return of({ value: 'false' });
      }),
    );

    return combineLatest([chatEnableParamObs, UserEnableChatObs]).pipe(
      map(([chatEnableParam, UserChatEnableParam]) => {
        if (chatEnableParam.value === 'true' && UserChatEnableParam.value === 'true') {
          this.getDefaultModel();
          this.setShowChat(true);
          return true;
        } else {
          this.setShowChat(false);
          return false;
        }
      }),
    );
  }
  private getDefaultModel() {
    this.chatGrpcServiceClient
      .getAzureOpenAiModel(
        new BaseGetRequest({
          where: new WhereGetRequest({
            predicate: new StringValue({ value: 'IsDefault = true' }),
          }),
        }),
      )
      .subscribe({
        next: response => {
          this.defaultModel = response;
          this.defaultModelSubject.next(response);
        },
        error: error => {
          console.log(error);
        },
      });
  }
  updateChats(chats: IChat[]) {
    this._chats.next(chats);
  }
  getOpenAiSummaryModelsUsage(chatId: number) {
    this.chatGrpcServiceClient
      .getChatOpenAiModelSummaryUsage(new GetChatOpenAiModelSummaryUsageRequest({ chatId: chatId }))
      .subscribe({
        next: response => {
          this.openAiModelsSummaryUsage.next(response.values ?? []);
        },
      });
  }

  get chats$(): Observable<IChat[]> {
    return this._chats.asObservable();
  }

  setChat(chat: IChat): void {
    chat.chat.messages = this._chat.getValue().chat.messages;
    this._chat.next(chat);
  }

  setShowChat(showChat: boolean) {
    this._showChat.next(showChat);
  }

  setNewChat(userId?: number): Observable<IChat> {
    return this.chatGrpcServiceClient
      .init(new InitRequest({ userId: new Int32Value({ value: userId }) }))
      .pipe(
        map(response => {
          const chatModel = new ChatModel();
          chatModel.id = response.id;
          chatModel.title = 'Smart Advisor ' + response.id;
          chatModel.modified = response.modified;
          if (response.history && response.history?.length > 0) {
            chatModel.messages?.push(response.history[1]);
          }
          const chat = { chat: chatModel, image: this.randomProfileImage() };
          const chats = this._chats.getValue();
          if (chats) {
            chats.unshift(chat);
            this._chats.next(chats);
          }
          this._chat.next(chat);
          return chat;
        }),
      );
  }
  getChats(): Observable<IChat[]> {
    return this.chatGrpcServiceClient.listChat(new Empty()).pipe(
      map(values => {
        const chats =
          values.values?.map(value => {
            value.title = value.title === '' ? 'Smart Advisor ' + value.id : value.title;

            return { chat: value, image: this.randomProfileImage() };
          }) ?? [];
        this._chats.next(chats);
        return chats;
      }),
    );

    // map(
    //   (values => {

    //     values.values?.map(value => {
    //       value.title = value.title === '' ? 'Smart Advisor ' + value.id : value.title;

    //       return { chat: value, image: this.randomProfileImage() };
    //     }) ?? [];

    // })
    // )
  }
  getChatById(id: number): Observable<IChat> {
    this.openAiModelsSummaryUsage.next(undefined);
    return (
      this.chatGrpcServiceClient
        .getChat(new GetChatRequest({ id: id }))
        // return this._httpClient
        //   .get<Chat>('api/apps/chat/chat', { params: { id } })
        .pipe(
          map(response => {
            // Update the chat
            response.title =
              response.title === '' ? 'Smart Advisor ' + response.id : response.title;

            const chats = this._chats.getValue();
            let image: string | undefined = undefined;
            if (chats) {
              image = chats.find(chat => chat.chat.id === id)?.image;
            }
            if (!image) {
              image = this.randomProfileImage();
            }

            this._chat.next({ chat: response, image: image });

            // Return the chat
            return response;
          }),
          switchMap(chat => {
            if (!chat) {
              return throwError('Could not found chat with id of ' + id + '!');
            }

            return of({ chat });
          }),
        )
    );
  }

  updateChat(id: number, chat: IChat): Observable<IChat> {
    return this.chats$.pipe(
      take(1),
      switchMap(chats =>
        this._httpClient
          .patch<IChat>('api/apps/chat/chat', {
            id,
            chat,
          })
          .pipe(
            map(updatedChat => {
              // Find the index of the updated chat
              const index = chats.findIndex(item => item.chat.id === id);

              // Update the chat
              chats[index] = updatedChat;

              // Update the chats
              this._chats.next(chats);

              // Return the updated contact
              return updatedChat;
            }),
            switchMap(updatedChat =>
              this.chat$.pipe(
                take(1),
                filter(item => item && item.chat.id === id),
                tap(() => {
                  // Update the chat if it's selected
                  this._chat.next(updatedChat);

                  // Return the updated chat
                  return updatedChat;
                }),
              ),
            ),
          ),
      ),
    );
  }
  deleteChat(chat: IChat) {
    return this.chatGrpcServiceClient.removeChat(new RemoveChatRequest({ id: chat.chat.id }));
  }

  resetChat(): void {
    this._chat.next(null);
  }

  private randomProfileImage(): string {
    const random = Math.floor(Math.random() * 9) + 1;
    // return `assets/img/valente-bots/${random}.jpg`;
    return `assets/img/valente-bots/usersvg.svg`;
  }
}
