/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as goal_transaction_enums002 from './goal-transaction-enums.pb';
import * as goal_transaction_type_enums003 from './goal-transaction-type-enums.pb';
import * as goal_transaction_payment_enum004 from './goal-transaction-payment-enum.pb';
import * as goal_transaction_origin_type_enum005 from './goal-transaction-origin-type-enum.pb';
import * as common_message006 from './common-message.pb';
import * as googleProtobuf007 from '@ngx-grpc/well-known-types';
export enum ChatMessageType {
  SYSTEM = 0,
  USER = 1,
  ASSISTANT = 2
}
export enum UserFeedbackRating {
  NONE = 0,
  POSITIVE = 1,
  NEGATIVE = 2
}
/**
 * Message implementation for chat.ChatMessageFeedbackModel
 */
export class ChatMessageFeedbackModel implements GrpcMessage {
  static id = 'chat.ChatMessageFeedbackModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ChatMessageFeedbackModel();
    ChatMessageFeedbackModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ChatMessageFeedbackModel) {
    _instance.id = _instance.id || 0;
    _instance.feedback = _instance.feedback || '';
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.chatMessageId = _instance.chatMessageId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ChatMessageFeedbackModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.feedback = _reader.readString();
          break;
        case 3:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.chatMessageId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ChatMessageFeedbackModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ChatMessageFeedbackModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.feedback) {
      _writer.writeString(2, _instance.feedback);
    }
    if (_instance.created) {
      _writer.writeMessage(
        3,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        4,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.chatMessageId) {
      _writer.writeInt32(5, _instance.chatMessageId);
    }
  }

  private _id: number;
  private _feedback: string;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _chatMessageId: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ChatMessageFeedbackModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<ChatMessageFeedbackModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.feedback = _value.feedback;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.chatMessageId = _value.chatMessageId;
    ChatMessageFeedbackModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get feedback(): string {
    return this._feedback;
  }
  set feedback(value: string) {
    this._feedback = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get chatMessageId(): number {
    return this._chatMessageId;
  }
  set chatMessageId(value: number) {
    this._chatMessageId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ChatMessageFeedbackModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ChatMessageFeedbackModel.AsObject {
    return {
      id: this.id,
      feedback: this.feedback,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      chatMessageId: this.chatMessageId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ChatMessageFeedbackModel.AsProtobufJSON {
    return {
      id: this.id,
      feedback: this.feedback,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      chatMessageId: this.chatMessageId
    };
  }
}
export module ChatMessageFeedbackModel {
  /**
   * Standard JavaScript object representation for ChatMessageFeedbackModel
   */
  export interface AsObject {
    id: number;
    feedback: string;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    chatMessageId: number;
  }

  /**
   * Protobuf JSON representation for ChatMessageFeedbackModel
   */
  export interface AsProtobufJSON {
    id: number;
    feedback: string;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    chatMessageId: number;
  }
}

/**
 * Message implementation for chat.AddFeedbackToChatMessageRequest
 */
export class AddFeedbackToChatMessageRequest implements GrpcMessage {
  static id = 'chat.AddFeedbackToChatMessageRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddFeedbackToChatMessageRequest();
    AddFeedbackToChatMessageRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddFeedbackToChatMessageRequest) {
    _instance.chatMessageId = _instance.chatMessageId || 0;
    _instance.feedback = _instance.feedback || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddFeedbackToChatMessageRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.chatMessageId = _reader.readInt32();
          break;
        case 2:
          _instance.feedback = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AddFeedbackToChatMessageRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddFeedbackToChatMessageRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.chatMessageId) {
      _writer.writeInt32(1, _instance.chatMessageId);
    }
    if (_instance.feedback) {
      _writer.writeString(2, _instance.feedback);
    }
  }

  private _chatMessageId: number;
  private _feedback: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddFeedbackToChatMessageRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<AddFeedbackToChatMessageRequest.AsObject>
  ) {
    _value = _value || {};
    this.chatMessageId = _value.chatMessageId;
    this.feedback = _value.feedback;
    AddFeedbackToChatMessageRequest.refineValues(this);
  }
  get chatMessageId(): number {
    return this._chatMessageId;
  }
  set chatMessageId(value: number) {
    this._chatMessageId = value;
  }
  get feedback(): string {
    return this._feedback;
  }
  set feedback(value: string) {
    this._feedback = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddFeedbackToChatMessageRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddFeedbackToChatMessageRequest.AsObject {
    return {
      chatMessageId: this.chatMessageId,
      feedback: this.feedback
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddFeedbackToChatMessageRequest.AsProtobufJSON {
    return {
      chatMessageId: this.chatMessageId,
      feedback: this.feedback
    };
  }
}
export module AddFeedbackToChatMessageRequest {
  /**
   * Standard JavaScript object representation for AddFeedbackToChatMessageRequest
   */
  export interface AsObject {
    chatMessageId: number;
    feedback: string;
  }

  /**
   * Protobuf JSON representation for AddFeedbackToChatMessageRequest
   */
  export interface AsProtobufJSON {
    chatMessageId: number;
    feedback: string;
  }
}

/**
 * Message implementation for chat.SetUserFeedbackRatingRequest
 */
export class SetUserFeedbackRatingRequest implements GrpcMessage {
  static id = 'chat.SetUserFeedbackRatingRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SetUserFeedbackRatingRequest();
    SetUserFeedbackRatingRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SetUserFeedbackRatingRequest) {
    _instance.chatMessageId = _instance.chatMessageId || 0;
    _instance.rating = _instance.rating || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SetUserFeedbackRatingRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.chatMessageId = _reader.readInt32();
          break;
        case 2:
          _instance.rating = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    SetUserFeedbackRatingRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SetUserFeedbackRatingRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.chatMessageId) {
      _writer.writeInt32(1, _instance.chatMessageId);
    }
    if (_instance.rating) {
      _writer.writeEnum(2, _instance.rating);
    }
  }

  private _chatMessageId: number;
  private _rating: UserFeedbackRating;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SetUserFeedbackRatingRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<SetUserFeedbackRatingRequest.AsObject>
  ) {
    _value = _value || {};
    this.chatMessageId = _value.chatMessageId;
    this.rating = _value.rating;
    SetUserFeedbackRatingRequest.refineValues(this);
  }
  get chatMessageId(): number {
    return this._chatMessageId;
  }
  set chatMessageId(value: number) {
    this._chatMessageId = value;
  }
  get rating(): UserFeedbackRating {
    return this._rating;
  }
  set rating(value: UserFeedbackRating) {
    this._rating = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SetUserFeedbackRatingRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SetUserFeedbackRatingRequest.AsObject {
    return {
      chatMessageId: this.chatMessageId,
      rating: this.rating
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SetUserFeedbackRatingRequest.AsProtobufJSON {
    return {
      chatMessageId: this.chatMessageId,
      rating:
        UserFeedbackRating[
          this.rating === null || this.rating === undefined ? 0 : this.rating
        ]
    };
  }
}
export module SetUserFeedbackRatingRequest {
  /**
   * Standard JavaScript object representation for SetUserFeedbackRatingRequest
   */
  export interface AsObject {
    chatMessageId: number;
    rating: UserFeedbackRating;
  }

  /**
   * Protobuf JSON representation for SetUserFeedbackRatingRequest
   */
  export interface AsProtobufJSON {
    chatMessageId: number;
    rating: string;
  }
}

/**
 * Message implementation for chat.GetChatRequest
 */
export class GetChatRequest implements GrpcMessage {
  static id = 'chat.GetChatRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetChatRequest();
    GetChatRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetChatRequest) {
    _instance.id = _instance.id || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetChatRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    GetChatRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetChatRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
  }

  private _id: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetChatRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetChatRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    GetChatRequest.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetChatRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetChatRequest.AsObject {
    return {
      id: this.id
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetChatRequest.AsProtobufJSON {
    return {
      id: this.id
    };
  }
}
export module GetChatRequest {
  /**
   * Standard JavaScript object representation for GetChatRequest
   */
  export interface AsObject {
    id: number;
  }

  /**
   * Protobuf JSON representation for GetChatRequest
   */
  export interface AsProtobufJSON {
    id: number;
  }
}

/**
 * Message implementation for chat.InitRequest
 */
export class InitRequest implements GrpcMessage {
  static id = 'chat.InitRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new InitRequest();
    InitRequest.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: InitRequest) {
    _instance.userId = _instance.userId || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: InitRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.userId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.userId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    InitRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: InitRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.userId) {
      _writer.writeMessage(
        1,
        _instance.userId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
  }

  private _userId?: googleProtobuf000.Int32Value;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of InitRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<InitRequest.AsObject>) {
    _value = _value || {};
    this.userId = _value.userId
      ? new googleProtobuf000.Int32Value(_value.userId)
      : undefined;
    InitRequest.refineValues(this);
  }
  get userId(): googleProtobuf000.Int32Value | undefined {
    return this._userId;
  }
  set userId(value: googleProtobuf000.Int32Value | undefined) {
    this._userId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    InitRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): InitRequest.AsObject {
    return {
      userId: this.userId ? this.userId.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): InitRequest.AsProtobufJSON {
    return {
      userId: this.userId ? this.userId.toProtobufJSON(options) : null
    };
  }
}
export module InitRequest {
  /**
   * Standard JavaScript object representation for InitRequest
   */
  export interface AsObject {
    userId?: googleProtobuf000.Int32Value.AsObject;
  }

  /**
   * Protobuf JSON representation for InitRequest
   */
  export interface AsProtobufJSON {
    userId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for chat.ListChatResponse
 */
export class ListChatResponse implements GrpcMessage {
  static id = 'chat.ListChatResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListChatResponse();
    ListChatResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListChatResponse) {
    _instance.values = _instance.values || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListChatResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new ChatModel();
          _reader.readMessage(
            messageInitializer1,
            ChatModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    ListChatResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListChatResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        ChatModel.serializeBinaryToWriter
      );
    }
  }

  private _values?: ChatModel[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListChatResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListChatResponse.AsObject>) {
    _value = _value || {};
    this.values = (_value.values || []).map(m => new ChatModel(m));
    ListChatResponse.refineValues(this);
  }
  get values(): ChatModel[] | undefined {
    return this._values;
  }
  set values(value: ChatModel[] | undefined) {
    this._values = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListChatResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListChatResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListChatResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module ListChatResponse {
  /**
   * Standard JavaScript object representation for ListChatResponse
   */
  export interface AsObject {
    values?: ChatModel.AsObject[];
  }

  /**
   * Protobuf JSON representation for ListChatResponse
   */
  export interface AsProtobufJSON {
    values: ChatModel.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for chat.ListChatMessageRequest
 */
export class ListChatMessageRequest implements GrpcMessage {
  static id = 'chat.ListChatMessageRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListChatMessageRequest();
    ListChatMessageRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListChatMessageRequest) {
    _instance.id = _instance.id || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListChatMessageRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ListChatMessageRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListChatMessageRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
  }

  private _id: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListChatMessageRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListChatMessageRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    ListChatMessageRequest.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListChatMessageRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListChatMessageRequest.AsObject {
    return {
      id: this.id
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListChatMessageRequest.AsProtobufJSON {
    return {
      id: this.id
    };
  }
}
export module ListChatMessageRequest {
  /**
   * Standard JavaScript object representation for ListChatMessageRequest
   */
  export interface AsObject {
    id: number;
  }

  /**
   * Protobuf JSON representation for ListChatMessageRequest
   */
  export interface AsProtobufJSON {
    id: number;
  }
}

/**
 * Message implementation for chat.ListChatMessageResponse
 */
export class ListChatMessageResponse implements GrpcMessage {
  static id = 'chat.ListChatMessageResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListChatMessageResponse();
    ListChatMessageResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListChatMessageResponse) {
    _instance.values = _instance.values || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListChatMessageResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new ChatMessageModel();
          _reader.readMessage(
            messageInitializer1,
            ChatMessageModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    ListChatMessageResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListChatMessageResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        ChatMessageModel.serializeBinaryToWriter
      );
    }
  }

  private _values?: ChatMessageModel[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListChatMessageResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListChatMessageResponse.AsObject>) {
    _value = _value || {};
    this.values = (_value.values || []).map(m => new ChatMessageModel(m));
    ListChatMessageResponse.refineValues(this);
  }
  get values(): ChatMessageModel[] | undefined {
    return this._values;
  }
  set values(value: ChatMessageModel[] | undefined) {
    this._values = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListChatMessageResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListChatMessageResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListChatMessageResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module ListChatMessageResponse {
  /**
   * Standard JavaScript object representation for ListChatMessageResponse
   */
  export interface AsObject {
    values?: ChatMessageModel.AsObject[];
  }

  /**
   * Protobuf JSON representation for ListChatMessageResponse
   */
  export interface AsProtobufJSON {
    values: ChatMessageModel.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for chat.RemoveChatRequest
 */
export class RemoveChatRequest implements GrpcMessage {
  static id = 'chat.RemoveChatRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RemoveChatRequest();
    RemoveChatRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RemoveChatRequest) {
    _instance.id = _instance.id || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RemoveChatRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    RemoveChatRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: RemoveChatRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
  }

  private _id: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RemoveChatRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<RemoveChatRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    RemoveChatRequest.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RemoveChatRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RemoveChatRequest.AsObject {
    return {
      id: this.id
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RemoveChatRequest.AsProtobufJSON {
    return {
      id: this.id
    };
  }
}
export module RemoveChatRequest {
  /**
   * Standard JavaScript object representation for RemoveChatRequest
   */
  export interface AsObject {
    id: number;
  }

  /**
   * Protobuf JSON representation for RemoveChatRequest
   */
  export interface AsProtobufJSON {
    id: number;
  }
}

/**
 * Message implementation for chat.UpdateChatRequest
 */
export class UpdateChatRequest implements GrpcMessage {
  static id = 'chat.UpdateChatRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateChatRequest();
    UpdateChatRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateChatRequest) {
    _instance.title = _instance.title || '';
    _instance.id = _instance.id || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateChatRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 2:
          _instance.title = _reader.readString();
          break;
        case 1:
          _instance.id = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateChatRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateChatRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.title) {
      _writer.writeString(2, _instance.title);
    }
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
  }

  private _title: string;
  private _id: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateChatRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateChatRequest.AsObject>) {
    _value = _value || {};
    this.title = _value.title;
    this.id = _value.id;
    UpdateChatRequest.refineValues(this);
  }
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateChatRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateChatRequest.AsObject {
    return {
      title: this.title,
      id: this.id
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateChatRequest.AsProtobufJSON {
    return {
      title: this.title,
      id: this.id
    };
  }
}
export module UpdateChatRequest {
  /**
   * Standard JavaScript object representation for UpdateChatRequest
   */
  export interface AsObject {
    title: string;
    id: number;
  }

  /**
   * Protobuf JSON representation for UpdateChatRequest
   */
  export interface AsProtobufJSON {
    title: string;
    id: number;
  }
}

/**
 * Message implementation for chat.ChatRequest
 */
export class ChatRequest implements GrpcMessage {
  static id = 'chat.ChatRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ChatRequest();
    ChatRequest.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ChatRequest) {
    _instance.content = _instance.content || '';
    _instance.id = _instance.id || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ChatRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 2:
          _instance.content = _reader.readString();
          break;
        case 1:
          _instance.id = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ChatRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ChatRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.content) {
      _writer.writeString(2, _instance.content);
    }
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
  }

  private _content: string;
  private _id: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ChatRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ChatRequest.AsObject>) {
    _value = _value || {};
    this.content = _value.content;
    this.id = _value.id;
    ChatRequest.refineValues(this);
  }
  get content(): string {
    return this._content;
  }
  set content(value: string) {
    this._content = value;
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ChatRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ChatRequest.AsObject {
    return {
      content: this.content,
      id: this.id
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ChatRequest.AsProtobufJSON {
    return {
      content: this.content,
      id: this.id
    };
  }
}
export module ChatRequest {
  /**
   * Standard JavaScript object representation for ChatRequest
   */
  export interface AsObject {
    content: string;
    id: number;
  }

  /**
   * Protobuf JSON representation for ChatRequest
   */
  export interface AsProtobufJSON {
    content: string;
    id: number;
  }
}

/**
 * Message implementation for chat.InitResponse
 */
export class InitResponse implements GrpcMessage {
  static id = 'chat.InitResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new InitResponse();
    InitResponse.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: InitResponse) {
    _instance.id = _instance.id || 0;
    _instance.history = _instance.history || [];
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: InitResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          const messageInitializer2 = new ChatMessageModel();
          _reader.readMessage(
            messageInitializer2,
            ChatMessageModel.deserializeBinaryFromReader
          );
          (_instance.history = _instance.history || []).push(
            messageInitializer2
          );
          break;
        case 3:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    InitResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: InitResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.history && _instance.history.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.history as any,
        ChatMessageModel.serializeBinaryToWriter
      );
    }
    if (_instance.created) {
      _writer.writeMessage(
        3,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        4,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _id: number;
  private _history?: ChatMessageModel[];
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of InitResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<InitResponse.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.history = (_value.history || []).map(m => new ChatMessageModel(m));
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    InitResponse.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get history(): ChatMessageModel[] | undefined {
    return this._history;
  }
  set history(value: ChatMessageModel[] | undefined) {
    this._history = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    InitResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): InitResponse.AsObject {
    return {
      id: this.id,
      history: (this.history || []).map(m => m.toObject()),
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): InitResponse.AsProtobufJSON {
    return {
      id: this.id,
      history: (this.history || []).map(m => m.toProtobufJSON(options)),
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null
    };
  }
}
export module InitResponse {
  /**
   * Standard JavaScript object representation for InitResponse
   */
  export interface AsObject {
    id: number;
    history?: ChatMessageModel.AsObject[];
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for InitResponse
   */
  export interface AsProtobufJSON {
    id: number;
    history: ChatMessageModel.AsProtobufJSON[] | null;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for chat.ChatModel
 */
export class ChatModel implements GrpcMessage {
  static id = 'chat.ChatModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ChatModel();
    ChatModel.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ChatModel) {
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.title = _instance.title || '';
    _instance.completionTokens = _instance.completionTokens || 0;
    _instance.promptTokens = _instance.promptTokens || 0;
    _instance.totalTokens = _instance.totalTokens || 0;
    _instance.messages = _instance.messages || [];
    _instance.openAiModelSummaryUsage = _instance.openAiModelSummaryUsage || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ChatModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.title = _reader.readString();
          break;
        case 5:
          _instance.completionTokens = _reader.readInt32();
          break;
        case 6:
          _instance.promptTokens = _reader.readInt32();
          break;
        case 7:
          _instance.totalTokens = _reader.readInt32();
          break;
        case 8:
          const messageInitializer8 = new ChatMessageModel();
          _reader.readMessage(
            messageInitializer8,
            ChatMessageModel.deserializeBinaryFromReader
          );
          (_instance.messages = _instance.messages || []).push(
            messageInitializer8
          );
          break;
        case 9:
          const messageInitializer9 = new OpenAiModelSummaryUsageModel();
          _reader.readMessage(
            messageInitializer9,
            OpenAiModelSummaryUsageModel.deserializeBinaryFromReader
          );
          (_instance.openAiModelSummaryUsage =
            _instance.openAiModelSummaryUsage || []).push(messageInitializer9);
          break;
        default:
          _reader.skipField();
      }
    }

    ChatModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: ChatModel, _writer: BinaryWriter) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        2,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        3,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.title) {
      _writer.writeString(4, _instance.title);
    }
    if (_instance.completionTokens) {
      _writer.writeInt32(5, _instance.completionTokens);
    }
    if (_instance.promptTokens) {
      _writer.writeInt32(6, _instance.promptTokens);
    }
    if (_instance.totalTokens) {
      _writer.writeInt32(7, _instance.totalTokens);
    }
    if (_instance.messages && _instance.messages.length) {
      _writer.writeRepeatedMessage(
        8,
        _instance.messages as any,
        ChatMessageModel.serializeBinaryToWriter
      );
    }
    if (
      _instance.openAiModelSummaryUsage &&
      _instance.openAiModelSummaryUsage.length
    ) {
      _writer.writeRepeatedMessage(
        9,
        _instance.openAiModelSummaryUsage as any,
        OpenAiModelSummaryUsageModel.serializeBinaryToWriter
      );
    }
  }

  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _title: string;
  private _completionTokens: number;
  private _promptTokens: number;
  private _totalTokens: number;
  private _messages?: ChatMessageModel[];
  private _openAiModelSummaryUsage?: OpenAiModelSummaryUsageModel[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ChatModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<ChatModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.title = _value.title;
    this.completionTokens = _value.completionTokens;
    this.promptTokens = _value.promptTokens;
    this.totalTokens = _value.totalTokens;
    this.messages = (_value.messages || []).map(m => new ChatMessageModel(m));
    this.openAiModelSummaryUsage = (_value.openAiModelSummaryUsage || []).map(
      m => new OpenAiModelSummaryUsageModel(m)
    );
    ChatModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }
  get completionTokens(): number {
    return this._completionTokens;
  }
  set completionTokens(value: number) {
    this._completionTokens = value;
  }
  get promptTokens(): number {
    return this._promptTokens;
  }
  set promptTokens(value: number) {
    this._promptTokens = value;
  }
  get totalTokens(): number {
    return this._totalTokens;
  }
  set totalTokens(value: number) {
    this._totalTokens = value;
  }
  get messages(): ChatMessageModel[] | undefined {
    return this._messages;
  }
  set messages(value: ChatMessageModel[] | undefined) {
    this._messages = value;
  }
  get openAiModelSummaryUsage(): OpenAiModelSummaryUsageModel[] | undefined {
    return this._openAiModelSummaryUsage;
  }
  set openAiModelSummaryUsage(
    value: OpenAiModelSummaryUsageModel[] | undefined
  ) {
    this._openAiModelSummaryUsage = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ChatModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ChatModel.AsObject {
    return {
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      title: this.title,
      completionTokens: this.completionTokens,
      promptTokens: this.promptTokens,
      totalTokens: this.totalTokens,
      messages: (this.messages || []).map(m => m.toObject()),
      openAiModelSummaryUsage: (this.openAiModelSummaryUsage || []).map(m =>
        m.toObject()
      )
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ChatModel.AsProtobufJSON {
    return {
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      title: this.title,
      completionTokens: this.completionTokens,
      promptTokens: this.promptTokens,
      totalTokens: this.totalTokens,
      messages: (this.messages || []).map(m => m.toProtobufJSON(options)),
      openAiModelSummaryUsage: (this.openAiModelSummaryUsage || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module ChatModel {
  /**
   * Standard JavaScript object representation for ChatModel
   */
  export interface AsObject {
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    title: string;
    completionTokens: number;
    promptTokens: number;
    totalTokens: number;
    messages?: ChatMessageModel.AsObject[];
    openAiModelSummaryUsage?: OpenAiModelSummaryUsageModel.AsObject[];
  }

  /**
   * Protobuf JSON representation for ChatModel
   */
  export interface AsProtobufJSON {
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    title: string;
    completionTokens: number;
    promptTokens: number;
    totalTokens: number;
    messages: ChatMessageModel.AsProtobufJSON[] | null;
    openAiModelSummaryUsage:
      | OpenAiModelSummaryUsageModel.AsProtobufJSON[]
      | null;
  }
}

/**
 * Message implementation for chat.OpenAiModelSummaryUsageModel
 */
export class OpenAiModelSummaryUsageModel implements GrpcMessage {
  static id = 'chat.OpenAiModelSummaryUsageModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new OpenAiModelSummaryUsageModel();
    OpenAiModelSummaryUsageModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: OpenAiModelSummaryUsageModel) {
    _instance.chatMessageCount = _instance.chatMessageCount || 0;
    _instance.totalPromptTokens = _instance.totalPromptTokens || 0;
    _instance.totalCompletionTokens = _instance.totalCompletionTokens || 0;
    _instance.totalTokens = _instance.totalTokens || 0;
    _instance.totalCost = _instance.totalCost || 0;
    _instance.azureOpenAiModelId = _instance.azureOpenAiModelId || 0;
    _instance.azureOpenAiModel = _instance.azureOpenAiModel || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: OpenAiModelSummaryUsageModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 4:
          _instance.chatMessageCount = _reader.readInt32();
          break;
        case 5:
          _instance.totalPromptTokens = _reader.readInt32();
          break;
        case 6:
          _instance.totalCompletionTokens = _reader.readInt32();
          break;
        case 7:
          _instance.totalTokens = _reader.readInt32();
          break;
        case 8:
          _instance.totalCost = _reader.readDouble();
          break;
        case 9:
          _instance.azureOpenAiModelId = _reader.readInt32();
          break;
        case 10:
          _instance.azureOpenAiModel = new AzureOpenAiModel();
          _reader.readMessage(
            _instance.azureOpenAiModel,
            AzureOpenAiModel.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    OpenAiModelSummaryUsageModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: OpenAiModelSummaryUsageModel,
    _writer: BinaryWriter
  ) {
    if (_instance.chatMessageCount) {
      _writer.writeInt32(4, _instance.chatMessageCount);
    }
    if (_instance.totalPromptTokens) {
      _writer.writeInt32(5, _instance.totalPromptTokens);
    }
    if (_instance.totalCompletionTokens) {
      _writer.writeInt32(6, _instance.totalCompletionTokens);
    }
    if (_instance.totalTokens) {
      _writer.writeInt32(7, _instance.totalTokens);
    }
    if (_instance.totalCost) {
      _writer.writeDouble(8, _instance.totalCost);
    }
    if (_instance.azureOpenAiModelId) {
      _writer.writeInt32(9, _instance.azureOpenAiModelId);
    }
    if (_instance.azureOpenAiModel) {
      _writer.writeMessage(
        10,
        _instance.azureOpenAiModel as any,
        AzureOpenAiModel.serializeBinaryToWriter
      );
    }
  }

  private _chatMessageCount: number;
  private _totalPromptTokens: number;
  private _totalCompletionTokens: number;
  private _totalTokens: number;
  private _totalCost: number;
  private _azureOpenAiModelId: number;
  private _azureOpenAiModel?: AzureOpenAiModel;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of OpenAiModelSummaryUsageModel to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<OpenAiModelSummaryUsageModel.AsObject>
  ) {
    _value = _value || {};
    this.chatMessageCount = _value.chatMessageCount;
    this.totalPromptTokens = _value.totalPromptTokens;
    this.totalCompletionTokens = _value.totalCompletionTokens;
    this.totalTokens = _value.totalTokens;
    this.totalCost = _value.totalCost;
    this.azureOpenAiModelId = _value.azureOpenAiModelId;
    this.azureOpenAiModel = _value.azureOpenAiModel
      ? new AzureOpenAiModel(_value.azureOpenAiModel)
      : undefined;
    OpenAiModelSummaryUsageModel.refineValues(this);
  }
  get chatMessageCount(): number {
    return this._chatMessageCount;
  }
  set chatMessageCount(value: number) {
    this._chatMessageCount = value;
  }
  get totalPromptTokens(): number {
    return this._totalPromptTokens;
  }
  set totalPromptTokens(value: number) {
    this._totalPromptTokens = value;
  }
  get totalCompletionTokens(): number {
    return this._totalCompletionTokens;
  }
  set totalCompletionTokens(value: number) {
    this._totalCompletionTokens = value;
  }
  get totalTokens(): number {
    return this._totalTokens;
  }
  set totalTokens(value: number) {
    this._totalTokens = value;
  }
  get totalCost(): number {
    return this._totalCost;
  }
  set totalCost(value: number) {
    this._totalCost = value;
  }
  get azureOpenAiModelId(): number {
    return this._azureOpenAiModelId;
  }
  set azureOpenAiModelId(value: number) {
    this._azureOpenAiModelId = value;
  }
  get azureOpenAiModel(): AzureOpenAiModel | undefined {
    return this._azureOpenAiModel;
  }
  set azureOpenAiModel(value: AzureOpenAiModel | undefined) {
    this._azureOpenAiModel = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    OpenAiModelSummaryUsageModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): OpenAiModelSummaryUsageModel.AsObject {
    return {
      chatMessageCount: this.chatMessageCount,
      totalPromptTokens: this.totalPromptTokens,
      totalCompletionTokens: this.totalCompletionTokens,
      totalTokens: this.totalTokens,
      totalCost: this.totalCost,
      azureOpenAiModelId: this.azureOpenAiModelId,
      azureOpenAiModel: this.azureOpenAiModel
        ? this.azureOpenAiModel.toObject()
        : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): OpenAiModelSummaryUsageModel.AsProtobufJSON {
    return {
      chatMessageCount: this.chatMessageCount,
      totalPromptTokens: this.totalPromptTokens,
      totalCompletionTokens: this.totalCompletionTokens,
      totalTokens: this.totalTokens,
      totalCost: this.totalCost,
      azureOpenAiModelId: this.azureOpenAiModelId,
      azureOpenAiModel: this.azureOpenAiModel
        ? this.azureOpenAiModel.toProtobufJSON(options)
        : null
    };
  }
}
export module OpenAiModelSummaryUsageModel {
  /**
   * Standard JavaScript object representation for OpenAiModelSummaryUsageModel
   */
  export interface AsObject {
    chatMessageCount: number;
    totalPromptTokens: number;
    totalCompletionTokens: number;
    totalTokens: number;
    totalCost: number;
    azureOpenAiModelId: number;
    azureOpenAiModel?: AzureOpenAiModel.AsObject;
  }

  /**
   * Protobuf JSON representation for OpenAiModelSummaryUsageModel
   */
  export interface AsProtobufJSON {
    chatMessageCount: number;
    totalPromptTokens: number;
    totalCompletionTokens: number;
    totalTokens: number;
    totalCost: number;
    azureOpenAiModelId: number;
    azureOpenAiModel: AzureOpenAiModel.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for chat.AzureOpenAiModel
 */
export class AzureOpenAiModel implements GrpcMessage {
  static id = 'chat.AzureOpenAiModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AzureOpenAiModel();
    AzureOpenAiModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AzureOpenAiModel) {
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.modelName = _instance.modelName || '';
    _instance.instanceName = _instance.instanceName || '';
    _instance.maxTokens = _instance.maxTokens || 0;
    _instance.description = _instance.description || '';
    _instance.completionCost = _instance.completionCost || 0;
    _instance.promptCost = _instance.promptCost || 0;
    _instance.isDefault = _instance.isDefault || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AzureOpenAiModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.modelName = _reader.readString();
          break;
        case 5:
          _instance.instanceName = _reader.readString();
          break;
        case 6:
          _instance.maxTokens = _reader.readInt32();
          break;
        case 7:
          _instance.description = _reader.readString();
          break;
        case 8:
          _instance.completionCost = _reader.readDouble();
          break;
        case 9:
          _instance.promptCost = _reader.readDouble();
          break;
        case 10:
          _instance.isDefault = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    AzureOpenAiModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AzureOpenAiModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        2,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        3,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modelName) {
      _writer.writeString(4, _instance.modelName);
    }
    if (_instance.instanceName) {
      _writer.writeString(5, _instance.instanceName);
    }
    if (_instance.maxTokens) {
      _writer.writeInt32(6, _instance.maxTokens);
    }
    if (_instance.description) {
      _writer.writeString(7, _instance.description);
    }
    if (_instance.completionCost) {
      _writer.writeDouble(8, _instance.completionCost);
    }
    if (_instance.promptCost) {
      _writer.writeDouble(9, _instance.promptCost);
    }
    if (_instance.isDefault) {
      _writer.writeBool(10, _instance.isDefault);
    }
  }

  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _modelName: string;
  private _instanceName: string;
  private _maxTokens: number;
  private _description: string;
  private _completionCost: number;
  private _promptCost: number;
  private _isDefault: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AzureOpenAiModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<AzureOpenAiModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.modelName = _value.modelName;
    this.instanceName = _value.instanceName;
    this.maxTokens = _value.maxTokens;
    this.description = _value.description;
    this.completionCost = _value.completionCost;
    this.promptCost = _value.promptCost;
    this.isDefault = _value.isDefault;
    AzureOpenAiModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get modelName(): string {
    return this._modelName;
  }
  set modelName(value: string) {
    this._modelName = value;
  }
  get instanceName(): string {
    return this._instanceName;
  }
  set instanceName(value: string) {
    this._instanceName = value;
  }
  get maxTokens(): number {
    return this._maxTokens;
  }
  set maxTokens(value: number) {
    this._maxTokens = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get completionCost(): number {
    return this._completionCost;
  }
  set completionCost(value: number) {
    this._completionCost = value;
  }
  get promptCost(): number {
    return this._promptCost;
  }
  set promptCost(value: number) {
    this._promptCost = value;
  }
  get isDefault(): boolean {
    return this._isDefault;
  }
  set isDefault(value: boolean) {
    this._isDefault = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AzureOpenAiModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AzureOpenAiModel.AsObject {
    return {
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      modelName: this.modelName,
      instanceName: this.instanceName,
      maxTokens: this.maxTokens,
      description: this.description,
      completionCost: this.completionCost,
      promptCost: this.promptCost,
      isDefault: this.isDefault
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AzureOpenAiModel.AsProtobufJSON {
    return {
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      modelName: this.modelName,
      instanceName: this.instanceName,
      maxTokens: this.maxTokens,
      description: this.description,
      completionCost: this.completionCost,
      promptCost: this.promptCost,
      isDefault: this.isDefault
    };
  }
}
export module AzureOpenAiModel {
  /**
   * Standard JavaScript object representation for AzureOpenAiModel
   */
  export interface AsObject {
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    modelName: string;
    instanceName: string;
    maxTokens: number;
    description: string;
    completionCost: number;
    promptCost: number;
    isDefault: boolean;
  }

  /**
   * Protobuf JSON representation for AzureOpenAiModel
   */
  export interface AsProtobufJSON {
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modelName: string;
    instanceName: string;
    maxTokens: number;
    description: string;
    completionCost: number;
    promptCost: number;
    isDefault: boolean;
  }
}

/**
 * Message implementation for chat.ChatMessageModel
 */
export class ChatMessageModel implements GrpcMessage {
  static id = 'chat.ChatMessageModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ChatMessageModel();
    ChatMessageModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ChatMessageModel) {
    _instance.message = _instance.message || '';
    _instance.id = _instance.id || 0;
    _instance.type = _instance.type || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.suggestions = _instance.suggestions || [];
    _instance.azureOpenAiModelId = _instance.azureOpenAiModelId || 0;
    _instance.azureOpenAiModel = _instance.azureOpenAiModel || undefined;
    _instance.usage = _instance.usage || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ChatMessageModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.message = _reader.readString();
          break;
        case 2:
          _instance.id = _reader.readInt32();
          break;
        case 3:
          _instance.type = _reader.readEnum();
          break;
        case 4:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 6:
          (_instance.suggestions = _instance.suggestions || []).push(
            _reader.readString()
          );
          break;
        case 7:
          _instance.azureOpenAiModelId = _reader.readInt32();
          break;
        case 8:
          _instance.azureOpenAiModel = new AzureOpenAiModel();
          _reader.readMessage(
            _instance.azureOpenAiModel,
            AzureOpenAiModel.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.usage = new ChatMessageUsageModel();
          _reader.readMessage(
            _instance.usage,
            ChatMessageUsageModel.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ChatMessageModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ChatMessageModel,
    _writer: BinaryWriter
  ) {
    if (_instance.message) {
      _writer.writeString(1, _instance.message);
    }
    if (_instance.id) {
      _writer.writeInt32(2, _instance.id);
    }
    if (_instance.type) {
      _writer.writeEnum(3, _instance.type);
    }
    if (_instance.created) {
      _writer.writeMessage(
        4,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        5,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.suggestions && _instance.suggestions.length) {
      _writer.writeRepeatedString(6, _instance.suggestions);
    }
    if (_instance.azureOpenAiModelId) {
      _writer.writeInt32(7, _instance.azureOpenAiModelId);
    }
    if (_instance.azureOpenAiModel) {
      _writer.writeMessage(
        8,
        _instance.azureOpenAiModel as any,
        AzureOpenAiModel.serializeBinaryToWriter
      );
    }
    if (_instance.usage) {
      _writer.writeMessage(
        9,
        _instance.usage as any,
        ChatMessageUsageModel.serializeBinaryToWriter
      );
    }
  }

  private _message: string;
  private _id: number;
  private _type: ChatMessageType;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _suggestions: string[];
  private _azureOpenAiModelId: number;
  private _azureOpenAiModel?: AzureOpenAiModel;
  private _usage?: ChatMessageUsageModel;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ChatMessageModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<ChatMessageModel.AsObject>) {
    _value = _value || {};
    this.message = _value.message;
    this.id = _value.id;
    this.type = _value.type;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.suggestions = (_value.suggestions || []).slice();
    this.azureOpenAiModelId = _value.azureOpenAiModelId;
    this.azureOpenAiModel = _value.azureOpenAiModel
      ? new AzureOpenAiModel(_value.azureOpenAiModel)
      : undefined;
    this.usage = _value.usage
      ? new ChatMessageUsageModel(_value.usage)
      : undefined;
    ChatMessageModel.refineValues(this);
  }
  get message(): string {
    return this._message;
  }
  set message(value: string) {
    this._message = value;
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get type(): ChatMessageType {
    return this._type;
  }
  set type(value: ChatMessageType) {
    this._type = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get suggestions(): string[] {
    return this._suggestions;
  }
  set suggestions(value: string[]) {
    this._suggestions = value;
  }
  get azureOpenAiModelId(): number {
    return this._azureOpenAiModelId;
  }
  set azureOpenAiModelId(value: number) {
    this._azureOpenAiModelId = value;
  }
  get azureOpenAiModel(): AzureOpenAiModel | undefined {
    return this._azureOpenAiModel;
  }
  set azureOpenAiModel(value: AzureOpenAiModel | undefined) {
    this._azureOpenAiModel = value;
  }
  get usage(): ChatMessageUsageModel | undefined {
    return this._usage;
  }
  set usage(value: ChatMessageUsageModel | undefined) {
    this._usage = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ChatMessageModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ChatMessageModel.AsObject {
    return {
      message: this.message,
      id: this.id,
      type: this.type,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      suggestions: (this.suggestions || []).slice(),
      azureOpenAiModelId: this.azureOpenAiModelId,
      azureOpenAiModel: this.azureOpenAiModel
        ? this.azureOpenAiModel.toObject()
        : undefined,
      usage: this.usage ? this.usage.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ChatMessageModel.AsProtobufJSON {
    return {
      message: this.message,
      id: this.id,
      type:
        ChatMessageType[
          this.type === null || this.type === undefined ? 0 : this.type
        ],
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      suggestions: (this.suggestions || []).slice(),
      azureOpenAiModelId: this.azureOpenAiModelId,
      azureOpenAiModel: this.azureOpenAiModel
        ? this.azureOpenAiModel.toProtobufJSON(options)
        : null,
      usage: this.usage ? this.usage.toProtobufJSON(options) : null
    };
  }
}
export module ChatMessageModel {
  /**
   * Standard JavaScript object representation for ChatMessageModel
   */
  export interface AsObject {
    message: string;
    id: number;
    type: ChatMessageType;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    suggestions: string[];
    azureOpenAiModelId: number;
    azureOpenAiModel?: AzureOpenAiModel.AsObject;
    usage?: ChatMessageUsageModel.AsObject;
  }

  /**
   * Protobuf JSON representation for ChatMessageModel
   */
  export interface AsProtobufJSON {
    message: string;
    id: number;
    type: string;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    suggestions: string[];
    azureOpenAiModelId: number;
    azureOpenAiModel: AzureOpenAiModel.AsProtobufJSON | null;
    usage: ChatMessageUsageModel.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for chat.ChatMessageUsageModel
 */
export class ChatMessageUsageModel implements GrpcMessage {
  static id = 'chat.ChatMessageUsageModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ChatMessageUsageModel();
    ChatMessageUsageModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ChatMessageUsageModel) {
    _instance.id = _instance.id || 0;
    _instance.created = _instance.created || undefined;
    _instance.modified = _instance.modified || undefined;
    _instance.completionTokens = _instance.completionTokens || 0;
    _instance.promptTokens = _instance.promptTokens || 0;
    _instance.totalTokens = _instance.totalTokens || 0;
    _instance.totalCost = _instance.totalCost || 0;
    _instance.chatMessageId = _instance.chatMessageId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ChatMessageUsageModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.created = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.created,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.modified = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modified,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.completionTokens = _reader.readInt32();
          break;
        case 5:
          _instance.promptTokens = _reader.readInt32();
          break;
        case 6:
          _instance.totalTokens = _reader.readInt32();
          break;
        case 7:
          _instance.totalCost = _reader.readDouble();
          break;
        case 8:
          _instance.chatMessageId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ChatMessageUsageModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ChatMessageUsageModel,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.created) {
      _writer.writeMessage(
        2,
        _instance.created as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modified) {
      _writer.writeMessage(
        3,
        _instance.modified as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.completionTokens) {
      _writer.writeInt32(4, _instance.completionTokens);
    }
    if (_instance.promptTokens) {
      _writer.writeInt32(5, _instance.promptTokens);
    }
    if (_instance.totalTokens) {
      _writer.writeInt32(6, _instance.totalTokens);
    }
    if (_instance.totalCost) {
      _writer.writeDouble(7, _instance.totalCost);
    }
    if (_instance.chatMessageId) {
      _writer.writeInt32(8, _instance.chatMessageId);
    }
  }

  private _id: number;
  private _created?: googleProtobuf001.Timestamp;
  private _modified?: googleProtobuf001.Timestamp;
  private _completionTokens: number;
  private _promptTokens: number;
  private _totalTokens: number;
  private _totalCost: number;
  private _chatMessageId: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ChatMessageUsageModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<ChatMessageUsageModel.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.created = _value.created
      ? new googleProtobuf001.Timestamp(_value.created)
      : undefined;
    this.modified = _value.modified
      ? new googleProtobuf001.Timestamp(_value.modified)
      : undefined;
    this.completionTokens = _value.completionTokens;
    this.promptTokens = _value.promptTokens;
    this.totalTokens = _value.totalTokens;
    this.totalCost = _value.totalCost;
    this.chatMessageId = _value.chatMessageId;
    ChatMessageUsageModel.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get created(): googleProtobuf001.Timestamp | undefined {
    return this._created;
  }
  set created(value: googleProtobuf001.Timestamp | undefined) {
    this._created = value;
  }
  get modified(): googleProtobuf001.Timestamp | undefined {
    return this._modified;
  }
  set modified(value: googleProtobuf001.Timestamp | undefined) {
    this._modified = value;
  }
  get completionTokens(): number {
    return this._completionTokens;
  }
  set completionTokens(value: number) {
    this._completionTokens = value;
  }
  get promptTokens(): number {
    return this._promptTokens;
  }
  set promptTokens(value: number) {
    this._promptTokens = value;
  }
  get totalTokens(): number {
    return this._totalTokens;
  }
  set totalTokens(value: number) {
    this._totalTokens = value;
  }
  get totalCost(): number {
    return this._totalCost;
  }
  set totalCost(value: number) {
    this._totalCost = value;
  }
  get chatMessageId(): number {
    return this._chatMessageId;
  }
  set chatMessageId(value: number) {
    this._chatMessageId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ChatMessageUsageModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ChatMessageUsageModel.AsObject {
    return {
      id: this.id,
      created: this.created ? this.created.toObject() : undefined,
      modified: this.modified ? this.modified.toObject() : undefined,
      completionTokens: this.completionTokens,
      promptTokens: this.promptTokens,
      totalTokens: this.totalTokens,
      totalCost: this.totalCost,
      chatMessageId: this.chatMessageId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ChatMessageUsageModel.AsProtobufJSON {
    return {
      id: this.id,
      created: this.created ? this.created.toProtobufJSON(options) : null,
      modified: this.modified ? this.modified.toProtobufJSON(options) : null,
      completionTokens: this.completionTokens,
      promptTokens: this.promptTokens,
      totalTokens: this.totalTokens,
      totalCost: this.totalCost,
      chatMessageId: this.chatMessageId
    };
  }
}
export module ChatMessageUsageModel {
  /**
   * Standard JavaScript object representation for ChatMessageUsageModel
   */
  export interface AsObject {
    id: number;
    created?: googleProtobuf001.Timestamp.AsObject;
    modified?: googleProtobuf001.Timestamp.AsObject;
    completionTokens: number;
    promptTokens: number;
    totalTokens: number;
    totalCost: number;
    chatMessageId: number;
  }

  /**
   * Protobuf JSON representation for ChatMessageUsageModel
   */
  export interface AsProtobufJSON {
    id: number;
    created: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modified: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    completionTokens: number;
    promptTokens: number;
    totalTokens: number;
    totalCost: number;
    chatMessageId: number;
  }
}

/**
 * Message implementation for chat.GetChatOpenAiModelSummaryUsageRequest
 */
export class GetChatOpenAiModelSummaryUsageRequest implements GrpcMessage {
  static id = 'chat.GetChatOpenAiModelSummaryUsageRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetChatOpenAiModelSummaryUsageRequest();
    GetChatOpenAiModelSummaryUsageRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetChatOpenAiModelSummaryUsageRequest) {
    _instance.chatId = _instance.chatId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetChatOpenAiModelSummaryUsageRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.chatId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    GetChatOpenAiModelSummaryUsageRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetChatOpenAiModelSummaryUsageRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.chatId) {
      _writer.writeInt32(1, _instance.chatId);
    }
  }

  private _chatId: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetChatOpenAiModelSummaryUsageRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetChatOpenAiModelSummaryUsageRequest.AsObject>
  ) {
    _value = _value || {};
    this.chatId = _value.chatId;
    GetChatOpenAiModelSummaryUsageRequest.refineValues(this);
  }
  get chatId(): number {
    return this._chatId;
  }
  set chatId(value: number) {
    this._chatId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetChatOpenAiModelSummaryUsageRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetChatOpenAiModelSummaryUsageRequest.AsObject {
    return {
      chatId: this.chatId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetChatOpenAiModelSummaryUsageRequest.AsProtobufJSON {
    return {
      chatId: this.chatId
    };
  }
}
export module GetChatOpenAiModelSummaryUsageRequest {
  /**
   * Standard JavaScript object representation for GetChatOpenAiModelSummaryUsageRequest
   */
  export interface AsObject {
    chatId: number;
  }

  /**
   * Protobuf JSON representation for GetChatOpenAiModelSummaryUsageRequest
   */
  export interface AsProtobufJSON {
    chatId: number;
  }
}

/**
 * Message implementation for chat.GetChatOpenAiModelSummaryUsageResponse
 */
export class GetChatOpenAiModelSummaryUsageResponse implements GrpcMessage {
  static id = 'chat.GetChatOpenAiModelSummaryUsageResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetChatOpenAiModelSummaryUsageResponse();
    GetChatOpenAiModelSummaryUsageResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetChatOpenAiModelSummaryUsageResponse) {
    _instance.values = _instance.values || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetChatOpenAiModelSummaryUsageResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new OpenAiModelSummaryUsageModel();
          _reader.readMessage(
            messageInitializer1,
            OpenAiModelSummaryUsageModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    GetChatOpenAiModelSummaryUsageResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetChatOpenAiModelSummaryUsageResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        OpenAiModelSummaryUsageModel.serializeBinaryToWriter
      );
    }
  }

  private _values?: OpenAiModelSummaryUsageModel[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetChatOpenAiModelSummaryUsageResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetChatOpenAiModelSummaryUsageResponse.AsObject>
  ) {
    _value = _value || {};
    this.values = (_value.values || []).map(
      m => new OpenAiModelSummaryUsageModel(m)
    );
    GetChatOpenAiModelSummaryUsageResponse.refineValues(this);
  }
  get values(): OpenAiModelSummaryUsageModel[] | undefined {
    return this._values;
  }
  set values(value: OpenAiModelSummaryUsageModel[] | undefined) {
    this._values = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetChatOpenAiModelSummaryUsageResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetChatOpenAiModelSummaryUsageResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetChatOpenAiModelSummaryUsageResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetChatOpenAiModelSummaryUsageResponse {
  /**
   * Standard JavaScript object representation for GetChatOpenAiModelSummaryUsageResponse
   */
  export interface AsObject {
    values?: OpenAiModelSummaryUsageModel.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetChatOpenAiModelSummaryUsageResponse
   */
  export interface AsProtobufJSON {
    values: OpenAiModelSummaryUsageModel.AsProtobufJSON[] | null;
  }
}
