/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as goal_transaction_enums002 from './goal-transaction-enums.pb';
import * as goal_transaction_type_enums003 from './goal-transaction-type-enums.pb';
import * as goal_transaction_payment_enum004 from './goal-transaction-payment-enum.pb';
import * as goal_transaction_origin_type_enum005 from './goal-transaction-origin-type-enum.pb';
import * as common_message006 from './common-message.pb';
/**
 * Message implementation for currency.AddCurrencyRequest
 */
export class AddCurrencyRequest implements GrpcMessage {
  static id = 'currency.AddCurrencyRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddCurrencyRequest();
    AddCurrencyRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddCurrencyRequest) {
    _instance.name = _instance.name || '';
    _instance.uuid = _instance.uuid || '';
    _instance.yahoomnemonic = _instance.yahoomnemonic || undefined;
    _instance.currencyCode = _instance.currencyCode || '';
    _instance.display = _instance.display || '';
    _instance.digitsInfo = _instance.digitsInfo || '';
    _instance.locale = _instance.locale || '';
    _instance.chartTooltipFormatNumber =
      _instance.chartTooltipFormatNumber || '';
    _instance.chartAxisFormatNumber = _instance.chartAxisFormatNumber || '';
    _instance.vectorCode = _instance.vectorCode || '';
    _instance.isUserBankAccountEnabled =
      _instance.isUserBankAccountEnabled || false;
    _instance.serverFormatNumber = _instance.serverFormatNumber || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddCurrencyRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 4:
          _instance.name = _reader.readString();
          break;
        case 5:
          _instance.uuid = _reader.readString();
          break;
        case 6:
          _instance.yahoomnemonic = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.yahoomnemonic,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.currencyCode = _reader.readString();
          break;
        case 10:
          _instance.display = _reader.readString();
          break;
        case 11:
          _instance.digitsInfo = _reader.readString();
          break;
        case 12:
          _instance.locale = _reader.readString();
          break;
        case 13:
          _instance.chartTooltipFormatNumber = _reader.readString();
          break;
        case 14:
          _instance.chartAxisFormatNumber = _reader.readString();
          break;
        case 15:
          _instance.vectorCode = _reader.readString();
          break;
        case 16:
          _instance.isUserBankAccountEnabled = _reader.readBool();
          break;
        case 17:
          _instance.serverFormatNumber = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AddCurrencyRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddCurrencyRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.name) {
      _writer.writeString(4, _instance.name);
    }
    if (_instance.uuid) {
      _writer.writeString(5, _instance.uuid);
    }
    if (_instance.yahoomnemonic) {
      _writer.writeMessage(
        6,
        _instance.yahoomnemonic as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.currencyCode) {
      _writer.writeString(9, _instance.currencyCode);
    }
    if (_instance.display) {
      _writer.writeString(10, _instance.display);
    }
    if (_instance.digitsInfo) {
      _writer.writeString(11, _instance.digitsInfo);
    }
    if (_instance.locale) {
      _writer.writeString(12, _instance.locale);
    }
    if (_instance.chartTooltipFormatNumber) {
      _writer.writeString(13, _instance.chartTooltipFormatNumber);
    }
    if (_instance.chartAxisFormatNumber) {
      _writer.writeString(14, _instance.chartAxisFormatNumber);
    }
    if (_instance.vectorCode) {
      _writer.writeString(15, _instance.vectorCode);
    }
    if (_instance.isUserBankAccountEnabled) {
      _writer.writeBool(16, _instance.isUserBankAccountEnabled);
    }
    if (_instance.serverFormatNumber) {
      _writer.writeString(17, _instance.serverFormatNumber);
    }
  }

  private _name: string;
  private _uuid: string;
  private _yahoomnemonic?: googleProtobuf000.StringValue;
  private _currencyCode: string;
  private _display: string;
  private _digitsInfo: string;
  private _locale: string;
  private _chartTooltipFormatNumber: string;
  private _chartAxisFormatNumber: string;
  private _vectorCode: string;
  private _isUserBankAccountEnabled: boolean;
  private _serverFormatNumber: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddCurrencyRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<AddCurrencyRequest.AsObject>) {
    _value = _value || {};
    this.name = _value.name;
    this.uuid = _value.uuid;
    this.yahoomnemonic = _value.yahoomnemonic
      ? new googleProtobuf000.StringValue(_value.yahoomnemonic)
      : undefined;
    this.currencyCode = _value.currencyCode;
    this.display = _value.display;
    this.digitsInfo = _value.digitsInfo;
    this.locale = _value.locale;
    this.chartTooltipFormatNumber = _value.chartTooltipFormatNumber;
    this.chartAxisFormatNumber = _value.chartAxisFormatNumber;
    this.vectorCode = _value.vectorCode;
    this.isUserBankAccountEnabled = _value.isUserBankAccountEnabled;
    this.serverFormatNumber = _value.serverFormatNumber;
    AddCurrencyRequest.refineValues(this);
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get uuid(): string {
    return this._uuid;
  }
  set uuid(value: string) {
    this._uuid = value;
  }
  get yahoomnemonic(): googleProtobuf000.StringValue | undefined {
    return this._yahoomnemonic;
  }
  set yahoomnemonic(value: googleProtobuf000.StringValue | undefined) {
    this._yahoomnemonic = value;
  }
  get currencyCode(): string {
    return this._currencyCode;
  }
  set currencyCode(value: string) {
    this._currencyCode = value;
  }
  get display(): string {
    return this._display;
  }
  set display(value: string) {
    this._display = value;
  }
  get digitsInfo(): string {
    return this._digitsInfo;
  }
  set digitsInfo(value: string) {
    this._digitsInfo = value;
  }
  get locale(): string {
    return this._locale;
  }
  set locale(value: string) {
    this._locale = value;
  }
  get chartTooltipFormatNumber(): string {
    return this._chartTooltipFormatNumber;
  }
  set chartTooltipFormatNumber(value: string) {
    this._chartTooltipFormatNumber = value;
  }
  get chartAxisFormatNumber(): string {
    return this._chartAxisFormatNumber;
  }
  set chartAxisFormatNumber(value: string) {
    this._chartAxisFormatNumber = value;
  }
  get vectorCode(): string {
    return this._vectorCode;
  }
  set vectorCode(value: string) {
    this._vectorCode = value;
  }
  get isUserBankAccountEnabled(): boolean {
    return this._isUserBankAccountEnabled;
  }
  set isUserBankAccountEnabled(value: boolean) {
    this._isUserBankAccountEnabled = value;
  }
  get serverFormatNumber(): string {
    return this._serverFormatNumber;
  }
  set serverFormatNumber(value: string) {
    this._serverFormatNumber = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddCurrencyRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddCurrencyRequest.AsObject {
    return {
      name: this.name,
      uuid: this.uuid,
      yahoomnemonic: this.yahoomnemonic
        ? this.yahoomnemonic.toObject()
        : undefined,
      currencyCode: this.currencyCode,
      display: this.display,
      digitsInfo: this.digitsInfo,
      locale: this.locale,
      chartTooltipFormatNumber: this.chartTooltipFormatNumber,
      chartAxisFormatNumber: this.chartAxisFormatNumber,
      vectorCode: this.vectorCode,
      isUserBankAccountEnabled: this.isUserBankAccountEnabled,
      serverFormatNumber: this.serverFormatNumber
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddCurrencyRequest.AsProtobufJSON {
    return {
      name: this.name,
      uuid: this.uuid,
      yahoomnemonic: this.yahoomnemonic
        ? this.yahoomnemonic.toProtobufJSON(options)
        : null,
      currencyCode: this.currencyCode,
      display: this.display,
      digitsInfo: this.digitsInfo,
      locale: this.locale,
      chartTooltipFormatNumber: this.chartTooltipFormatNumber,
      chartAxisFormatNumber: this.chartAxisFormatNumber,
      vectorCode: this.vectorCode,
      isUserBankAccountEnabled: this.isUserBankAccountEnabled,
      serverFormatNumber: this.serverFormatNumber
    };
  }
}
export module AddCurrencyRequest {
  /**
   * Standard JavaScript object representation for AddCurrencyRequest
   */
  export interface AsObject {
    name: string;
    uuid: string;
    yahoomnemonic?: googleProtobuf000.StringValue.AsObject;
    currencyCode: string;
    display: string;
    digitsInfo: string;
    locale: string;
    chartTooltipFormatNumber: string;
    chartAxisFormatNumber: string;
    vectorCode: string;
    isUserBankAccountEnabled: boolean;
    serverFormatNumber: string;
  }

  /**
   * Protobuf JSON representation for AddCurrencyRequest
   */
  export interface AsProtobufJSON {
    name: string;
    uuid: string;
    yahoomnemonic: googleProtobuf000.StringValue.AsProtobufJSON | null;
    currencyCode: string;
    display: string;
    digitsInfo: string;
    locale: string;
    chartTooltipFormatNumber: string;
    chartAxisFormatNumber: string;
    vectorCode: string;
    isUserBankAccountEnabled: boolean;
    serverFormatNumber: string;
  }
}

/**
 * Message implementation for currency.ListCurrencyResponse
 */
export class ListCurrencyResponse implements GrpcMessage {
  static id = 'currency.ListCurrencyResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListCurrencyResponse();
    ListCurrencyResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListCurrencyResponse) {
    _instance.values = _instance.values || [];
    _instance.currentPage = _instance.currentPage || 0;
    _instance.pageCount = _instance.pageCount || 0;
    _instance.pageSize = _instance.pageSize || 0;
    _instance.rowCount = _instance.rowCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListCurrencyResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message006.CurrencyModel();
          _reader.readMessage(
            messageInitializer1,
            common_message006.CurrencyModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.currentPage = _reader.readInt32();
          break;
        case 3:
          _instance.pageCount = _reader.readInt32();
          break;
        case 4:
          _instance.pageSize = _reader.readInt32();
          break;
        case 5:
          _instance.rowCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ListCurrencyResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListCurrencyResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        common_message006.CurrencyModel.serializeBinaryToWriter
      );
    }
    if (_instance.currentPage) {
      _writer.writeInt32(2, _instance.currentPage);
    }
    if (_instance.pageCount) {
      _writer.writeInt32(3, _instance.pageCount);
    }
    if (_instance.pageSize) {
      _writer.writeInt32(4, _instance.pageSize);
    }
    if (_instance.rowCount) {
      _writer.writeInt32(5, _instance.rowCount);
    }
  }

  private _values?: common_message006.CurrencyModel[];
  private _currentPage: number;
  private _pageCount: number;
  private _pageSize: number;
  private _rowCount: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListCurrencyResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListCurrencyResponse.AsObject>) {
    _value = _value || {};
    this.values = (_value.values || []).map(
      m => new common_message006.CurrencyModel(m)
    );
    this.currentPage = _value.currentPage;
    this.pageCount = _value.pageCount;
    this.pageSize = _value.pageSize;
    this.rowCount = _value.rowCount;
    ListCurrencyResponse.refineValues(this);
  }
  get values(): common_message006.CurrencyModel[] | undefined {
    return this._values;
  }
  set values(value: common_message006.CurrencyModel[] | undefined) {
    this._values = value;
  }
  get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(value: number) {
    this._currentPage = value;
  }
  get pageCount(): number {
    return this._pageCount;
  }
  set pageCount(value: number) {
    this._pageCount = value;
  }
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }
  get rowCount(): number {
    return this._rowCount;
  }
  set rowCount(value: number) {
    this._rowCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListCurrencyResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListCurrencyResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListCurrencyResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }
}
export module ListCurrencyResponse {
  /**
   * Standard JavaScript object representation for ListCurrencyResponse
   */
  export interface AsObject {
    values?: common_message006.CurrencyModel.AsObject[];
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }

  /**
   * Protobuf JSON representation for ListCurrencyResponse
   */
  export interface AsProtobufJSON {
    values: common_message006.CurrencyModel.AsProtobufJSON[] | null;
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }
}
